<template>
    <div class="login">
        <div class="login-signin">
            <h4>Reset Password</h4>
            <div class="input">
                <input v-model="login" type="text" placeholder="Username" name="login" id="login1" required />
            </div>
            <div class="input">
                <input v-model="password" type="password" placeholder="Password" name="password" id="password1" required />
            </div>
            <div class="input">
                <input v-model="code" type="number" placeholder="Verification Code" name="code" id="code" required />
            </div>
            <p class="error error-message" v-if="showError">{{ error }}</p>
            <p class="success success-message" v-if="showSuccess">{{ success }}</p>
            <div class="login-button">
                <button @click="resetPassword" :disabled="isEmptyString(login) || isEmptyString(password) || isEmptyString(code)">Reset</button>
            </div>
            <div class="login-button-secondary">
                <button @click="goToSignIn">Login</button>
            </div>
            <div class="login-signin login-reset">
                <div class="input">
                    <input v-model="login" type="text" placeholder="Username" name="login" id="login2" required />
                </div>
                <p v-if="codeSent">{{ emailmsg }}</p>
                <div class="login-button-secondary">
                    <button @click="getVerificationCode" :disabled="isEmptyString(login)">Get Verification Code</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
    name: 'ResetPassword',
    data() {
        return {
            login: '',
            password: '',
            error: '',
            code: '',
            emailmsg: 'We will send the Verification code to your email',
            codeSent: false,
            success: 'Your password is updated, Please login using your new password.',
            showSuccess: false,
            showError: false,
        };
    },
    methods: {
        goToSignIn() {
            this.$router.push('/');
        },
        getVerificationCode() {
            this.codeSent = true;
            Auth.forgotPassword(this.login)
                .then(() => {
                    this.emailmsg = 'We will send the Verification code to your email';
                    this.showSuccess = false;
                    this.showError = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.showError = true;
                    this.showSuccess = false;
                    this.emailmsg = err.message;
                });
        },
        resetPassword() {
            this.error = '';
            Auth.forgotPasswordSubmit(this.login, this.code, this.password)
                .then(() => {
                    this.showError = false;
                    this.showSuccess = true;
                })
                .catch((err) => {
                    this.error = err.message;
                    this.showError = true;
                    this.showSuccess = false;
                });
        },
    },
};
</script>
